.container {
  padding: 1.5rem;
  display: flex;
}
.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
}
.logo img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo span {
  font-weight: 600;
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.menu {
  display: flex;
  gap: 2rem;
  font-weight: 500;
  list-style: none;
}
.menu li:hover {
  color: #fe956f;
  cursor: pointer;
}
.search {
  width: 7rem;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  height: 30%;
}
.cart {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.bars {
  display: none;
}
@media (max-width: 640px) {
  .right {
    position: absolute;
    right: 2rem;
    z-index: 99;
    background-color: white;
    color: var(--black);
    border-radius: 5px;
    flex-direction: column;
    padding: 1rem;
  }
  .menu {
    flex-direction: column;
    margin-left: -2rem;
    display: none;
  }
  .bars {
    display: block;
  }

}

@media (max-width: 856px) {
  .search {
    display: none;
  }
  .right {
    gap: 1rem;
  }
}
